import { useCallback, useEffect, useState } from 'react'
import { Input } from '../shadcn/input'
import debounce from '../../utils/debounce'
import { fetchLocationsAndTopics, getDefaultData } from './helper'
import Loading from '../Loading'

type Props = {
  onSelectTopic: (v: any) => void
  onSelectLocation: (v: any) => void
}

export default function TopicSelectDialogContent({
  onSelectLocation,
  onSelectTopic,
}: Props) {
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>({
    topics: [],
    locations: [],
  })

  useEffect(() => {
    getDefaultData().then((data) => {
      setData({
        topics: data.topics || [],
        locations: data.locations || [],
      })
    })
  }, [])

  const debouncedOnChange = useCallback(
    debounce((value) => {
      // console.log('Input value:', value)
      if (value) {
        setLoading(true)
        fetchLocationsAndTopics(value)
          .then((data) => {
            setData(data)
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }, 300),
    []
  )

  return (
    <div className="p-5">
      <Input
        className=""
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value)
          debouncedOnChange(e.target.value)
        }}
      />
      <div className="flex gap-1 flex-row-reverse">
        <div className="flex-1 text-xs mt-1">
          <h2 className="text-border ml-1">Locations</h2>
          <ul className="mt-2 font-bold flex flex-col gap-1 h-[300px] overflow-y-scroll">
            {loading && (
              <div className="flex justify-center mt-5">
                <Loading />
              </div>
            )}
            {(data.locations || []).map((x: any, i: number) => (
              <li
                key={i}
                className="hover:bg-gray-100 transition-all p-1 rounded-sm cursor-pointer"
                onClick={() => {
                  onSelectLocation(x.wholeObject)
                }}
              >
                {x.label}
                <div key={i} className={'mt-0.5'}>
                  <span
                    className={
                      'bg-gray-200 p-0.5 rounded font-normal text-gray-500'
                    }
                  >
                    {x.wholeObject.addresstype}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className=" w-[1px] bg-line" />
        <div className="flex-1 text-xs mt-1">
          <h2 className="text-border ml-1">Topics</h2>
          <ul className="mt-2 font-bold flex flex-col gap-1 h-[300px] overflow-auto">
            {loading && (
              <div className="flex justify-center mt-5">
                <Loading />
              </div>
            )}
            {(data.topics || []).map((x: any, i: number) => {
              return (
                <li
                  key={i}
                  className="hover:bg-gray-100 transition-all p-1 rounded-sm cursor-pointer"
                  onClick={() => {
                    onSelectTopic(x)
                  }}
                >
                  {x.label}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}
