import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import './index.css'

import HeadingAndValue from '../../components/HeadingAndValue'

import edit from '../../assets/icons/edit.svg'
import Button from '../../components-v2/Button'
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '../../components-v2/shadcn/avatar'
import { Switch } from '../../components-v2/shadcn/switch'
import React, { useState } from 'react'
import { getInitials } from '../../components-v2/Post/helpers'
import OnBoardButton from '../../components/OnBoardButton'
import ResetPasswordDialog from './ResetPasswordDialog'
import RequestUserTypeDialog from './RequestUserTypeDialog'
import { getOrganization } from '../../redux/organizations/actions'
import { createHouseguest, updateEmailPreference } from '../../services/user'
import { toast } from 'sonner'
import { getMe } from '../../redux/login/actions'
import EmailSettingForm from './EmailSettingForm'

const UserDetails = ({ userDetails, getMe }: any) => {
  const navigate = useNavigate()
  const separateNames = userDetails?.name.split(' ')
  const [loading, setLoading] = useState(false)
  return (
    <>
      <div className="main-div-user-details overflow-x-hidden px-2">
        {/*main div*/}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '12px',
          }}
        >
          <div style={{ width: '24px' }}></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            <text className={'check-text-user-details'}>
              {userDetails?.name}
            </text>
          </div>
          <div style={{ flexDirection: 'row', alignItems: 'center' }}>
            <img
              className={'edit-pencil-user-details'}
              src={edit}
              onClick={() => {
                navigate('/editprofiledetails')
              }}
              alt=""
            />
          </div>
        </div>

        <div
          className={'logo-user-details flex items-center justify-center mt-6'}
        >
          <Avatar className="h-[200px] w-[200px] rounded-full border border-border object-cover">
            <AvatarImage src={userDetails?.imageUrl} className="" />
            <AvatarFallback className={'text-6xl text-gray-600'}>
              {getInitials(userDetails?.name)}
            </AvatarFallback>
          </Avatar>
          {/*check logo*/}
        </div>

        <div style={{ marginTop: '40px', width: '100%' }} className={''}>
          <HeadingAndValue heading={'Login'} value={userDetails?.email} />
          <HeadingAndValue
            heading={'First Name'}
            value={separateNames ? separateNames[0] : null}
          />
          <HeadingAndValue
            heading={'Last Name'}
            value={separateNames ? separateNames[1] : null}
          />
          <HeadingAndValue
            heading={'Hometown'}
            value={userDetails?.userLocations?.address}
          />
          <HeadingAndValue
            heading={'User type'}
            value={userDetails?.roles?.name}
          />
          <HeadingAndValue
            heading={'Phone Number'}
            value={userDetails?.phoneNumber}
          />
          <HeadingAndValue
            heading={'Date Of Birth'}
            value={userDetails?.dateOfBirth}
          />
          <HeadingAndValue
            heading={'About yourself'}
            value={userDetails?.description}
          />
        </div>

        <div className="mt-5 space-y-2">
          <p className="font-semibold text-xs">Email settings</p>

          <EmailSettingForm token={userDetails?.emailToken} />
        </div>

        <div className="mt-10 flex flex-col items-center gap-2">
          <OnBoardButton
            onClick={() => navigate('/user/' + userDetails?.id)}
            width={'250px'}
            disabled={false}
            buttonName={'See posts and activity'}
          />
          <ResetPasswordDialog
            trigger={<Button className="min-w-[250px]">Reset Pasword</Button>}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userDetails: state.login.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getMe: () => {
      dispatch(getMe())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
