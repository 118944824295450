import axios from 'axios'
import authAxios from '../../utils/authAxios'
import { sanitizeLocation } from '../../utils/osmHelpers'

export function getDescriptionPaceholder(type: String) {
  switch (type) {
    case 'Fact':
      return 'Share factual elements with the world'
    case 'Research':
      return 'Tell the world what info and conclusions you have gathered'
    case 'Opinion':
      return 'Tell the world what you believe in'
    case 'Rumor':
      return 'Tell the world what you heard'
    case 'Humor':
      return 'Hahahahaha'
    default:
      return ''
  }
}

export function getDialogTitle(dialogType: string, currentPostType: string) {
  switch (dialogType) {
    case 'comment':
      return `Comment to another ${currentPostType} post`
    case 'contrast':
      return `Contrast to another ${currentPostType} post`
  }
}

export async function fetchLocationsAndTopics(searchText: string) {
  // fetch topics
  const topicsRes = await authAxios.get(`/topic/search/${searchText}`)

  // fetch locations
  const locationsRes = await axios.get(
    'https://nominatim.openstreetmap.org/search?q=' +
      searchText +
      '&format=json&countrycodes=us&addressdetails=1&accept-language=us'
  )
  sanitizeLocation(locationsRes?.data)
  const locations = locationsRes?.data?.map((x: any) => {
    return {
      label: x.display_name,
      value: x.place_id,
      wholeObject: x,
    }
  })

  const topics = topicsRes?.data?.map((x: any) => {
    return {
      label: x.title,
      value: x.id,
    }
  })

  return {
    topics: topics,
    locations: locations,
  }
}

export async function fetchPostEditRules(postId: string) {
  const res = await authAxios.get(`/post/update/${postId}`)
  return res.data
}

export async function getDefaultData() {
  const res = await authAxios.get(`/v2/post/getDefaultData`)
  return res.data
}

export async function getPostAuthorOrganizations(postId: string) {
  const res = await authAxios.post(`/v2/post/getPostAuthorOrganizations`, {
    postId: postId,
  })
  return res.data
}
