import { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { addPostsToRedux } from '../../redux/analytics/actions'
import {
  alarmTogglePost,
  deletePost,
  getAllPosts,
  getAllPostsForTopics,
  getPost,
  setClickedPost,
  voteTogglePost,
} from '../../redux/posts/actions'
import { setClickedTopic } from '../../redux/topics/actions'
import AnalyticsTrackerWrapper from '../AnalyticsTrackerWrapper'
import './index.css'
import PostWithContrast from '../../components-v2/Post/PostWithContrast'
import PostCard from '../../components-v2/Post/PostCard'
import Tabs from '../../components-v2/Tabs'
import ContrastsTab from './tabs/ContrastsTab'
import CommentsTab from './tabs/CommentsTab'
import ArticlesTab from './tabs/ArticlesTab'
import ArrowLeftSVG from '../../assets/icons/arrow-small-left.svg'
import { getTopic } from './services'
import {
  alarmToggle,
  followUsers,
  upvoteToggle,
} from '../../components-v2/Post/services'
import { getOnePost, getPostWithTrails } from '../../services/post'
import { Post } from '../../types'
import ConversationTrail from './ConversationTrail'
import scrollToDiv from '../../utils/scrollToDiv'
import { cn } from '../../utils/cn'
import { parsePostObjToProps } from '../../components-v2/Post/helpers'
import Header from '../../components-v2/Header/Header'
import { getAllowedPostTypes } from '../../utils/permissions'
import { getMe } from '../../redux/login/actions'
import Loading from '../../components-v2/Loading'
// import { useUserStore } from '../UserScreen-v2/user.store'
import { getUserFollowerIds, getUserVoteIds } from '../../services/helpersApis'
import { useIdsStore } from '../../components-v2/Post/post.store'

// function getTab(currentTab: string) {
//   switch (currentTab) {
//     case 'contrasts':
//       return <ContrastsTab />
//     case 'comments':
//       return <CommentsTab />
//     case 'articles':
//       return <ArticlesTab />
//   }
// }

const TopicScreen = ({
  topic: selectedTopic = {},
  post: selectedPost = {},

  getPost,
  getTopic: getTopicRedux,
  deletePost,

  setPostModalVisible,
  setContrastsModalVisible,
  getAllPostsForTopics,

  setClickedPost,
  setClickedTopic,
  voteTogglePost,
  alarmTogglePost,
  setPageArticle,
  user,
  permissions,
  addPostsToRedux,
  getMe,
}: any) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tabParam = searchParams.get('tab')

  const [post, setPost] = useState<Post | null>(null)
  const [loading, setLoading] = useState(false)

  const [trailPosts, setTrailPosts] = useState<Post[]>([])
  const [currentTab, setCurrentTab] = useState(
    tabParam ? tabParam : 'contrasts'
  )
  const [topic, setTopic] = useState<any>(null)
  const navigate = useNavigate()

  const params = useParams<{ postId: string; topicId: string }>()
  const { topicId, postId } = params
  const [isLocationModerator, setIsLocationModerator] = useState(false)

  // const [postAvailability, setPostAvailability] = useState<any>(null)
  // const setUserFollowIds = useUserStore((state: any) => state.setUserFollowIds)
  useEffect(() => {
    getMe()
    // getUserFollowerIds().then((userIds: any) => {
    //   setUserFollowIds(userIds)
    // })
  }, [])

  const pageLoad = useCallback(() => {
    if (postId) {
      setLoading(true)
      setCurrentTab(tabParam ? tabParam : 'contrasts')
      getPostWithTrails(postId)
        .then(({ trailPosts, currentPost }) => {
          setLoading(false)
          if (trailPosts) {
            setTrailPosts(trailPosts)
          } else {
            setTrailPosts([])
          }

          if (currentPost) {
            setIsLocationModerator(currentPost?.isLocationModerator)
            setPost(currentPost)
          }

          if (!tabParam && currentPost?.con?.length < 1) {
            setCurrentTab('comments')
          }
        })
        .catch(() => {
          setLoading(false)
        })
    }

    if (topicId) {
      getTopic(topicId).then((data: any) => {
        // console.log('🚀 ~ getTopic ~ data:', data)
        setTopic(data)
      })
    }
  }, [postId, topicId, tabParam])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  useEffect(() => {
    if (trailPosts.length <= 0) {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      })
    }
  }, [trailPosts])

  let tab = null
  switch (currentTab) {
    case 'contrasts':
      tab = (
        <ContrastsTab
          post={post}
          contrastsAllow={permissions?.contrastsCreation}
        />
      )
      break

    case 'comments':
      tab = <CommentsTab post={post} />
      break

    case 'articles':
      tab = <ArticlesTab post={post} topicId={topicId} />
      break
  }

  const tabsToShow = [
    {
      value: 'comments',
      label: 'Comments',
    },
    {
      value: 'contrasts',
      label: 'Contrasts',
    },
  ]

  // if (permissions?.contrastsCreation) {
  //   tabsToShow.push({
  //     value: 'contrasts',
  //     label: 'Contrasts',
  //   })
  // }

  if (post?.articleId) {
    tabsToShow.push({
      value: 'articles',
      label: 'Articles',
    })
  }
  const setUserFollowIds = useIdsStore((state: any) => state.setUserFollowIds)
  function onClickFollowUser(userId: any) {
    followUsers(userId).then(() => {
      //pageLoad()
      if (getUserFollowerIds) {
        getUserFollowerIds().then((userIds) => {
          setUserFollowIds(userIds)
        })
      }
    })
  }

  const setUserVoteIds = useIdsStore((state: any) => state.setUserVoteIds)

  const [isClickedVote, setIsClickedVote] = useState(false)

  function onClickVotePost(postId: any) {
    // setFakeVoteCount(1)
    setIsClickedVote(true)
    upvoteToggle(postId).then(() => {
      getUserVoteIds()
        .then((postIds: any) => {
          setUserVoteIds(postIds)
        })
        .catch((err) => {
          console.log('Err::::::', err)
        })
    })
  }
  if (!loading && !post) {
    return (
      <AnalyticsTrackerWrapper>
        <Header />

        <div className="flex justify-center mt-10">
          <h1 className="text-border text-xs">
            Post is deleted or not available.
          </h1>
        </div>
      </AnalyticsTrackerWrapper>
    )
  }

  return (
    <AnalyticsTrackerWrapper>
      <Header />
      {/* {loading && (
        <div className="mt-[50px] bg-red-300">
          <Loading />
        </div>
      )} */}
      <div className="fixed top-10 z-10 flex h-11 w-full items-center justify-center bg-line px-5 text-sm font-semibold">
        <div className="flex w-[364px] items-center justify-between">
          <img
            src={ArrowLeftSVG}
            className="h-6 w-6 cursor-pointer rounded-lg transition-all hover:bg-border"
            onClick={() => {
              navigate(-1)
            }}
          />
          <h1
            className="cursor-pointer mx-mobile:text-xs"
            onClick={() => {
              navigate(`/topic/${topic?.id}`)
            }}
          >
            {topic?.title}
          </h1>
          <div className="w-6" />
        </div>
      </div>
      <div className="mx-auto flex w-full max-w-[2000px] mx-desktop:flex-col">
        {/* Left div */}
        <div
          className="min-h-44 mx-desktop:hidden"
          style={{
            flex: '0 0 30%',
          }}
        ></div>

        {/* Middle div */}
        <div
          className="flex min-h-screen flex-col items-center border-r border-line pt-[60px] mx-desktop:border-r-0 mx-mobile:px-5"
          style={{
            flex: '0 0 40%',
          }}
        >
          {/* trail line */}
          {trailPosts.length > 0 && (
            <ConversationTrail trailPosts={trailPosts} cb={pageLoad} />
          )}

          <div
            className={cn(
              'flex min-h-[calc(100vh-109px)] w-full flex-col items-center',
              {
                // 'mt-5': trailPosts.length <= 0,
              }
            )}
            id="trail-start"
          >
            {post && (
              <PostCard
                onClickFollowUser={onClickFollowUser}
                addPostsToRedux={addPostsToRedux}
                postTypeForAnalytics={'home'}
                userLocation={null}
                {...parsePostObjToProps(post)}
                urlsCollapsed={false}
                onClickVote={() => {
                  onClickVotePost(post?.id)
                }}
                onClickAlarm={() => {
                  alarmToggle(post.id).then(() => {
                    pageLoad()
                  })
                }}
                cb={pageLoad}
                isLocationModerator={isLocationModerator}
                isClickedVote={isClickedVote}
              />
            )}

            {/* Tabs */}
            <Tabs
              className="mb-6 mt-6"
              setCurrentTab={setCurrentTab}
              currentTab={currentTab}
              tabs={tabsToShow}
            />

            {tab}

            {/* {posts.map((x, i) => {
            return <PostWithContrast hasContrast={x.hasCon} />
          })} */}
          </div>
        </div>

        {/* Right div */}
        <div
          className="relative min-h-44 mx-desktop:hidden"
          style={{
            flex: '0 0 30%',
          }}
        ></div>
      </div>
    </AnalyticsTrackerWrapper>
  )
}

const mapStateToProps = (state: any) => {
  return {
    posts: state.posts.posts,
    topicPosts: state.posts.topicPosts,
    postsFailed: state.posts.postsFailed,
    permissions: state?.login?.user?.roles?.permissions,

    topic: state.topics.topic,
    post: state.posts.post,
    user: state.login.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getAllPosts: () => {
      dispatch(getAllPosts())
    },
    getAllPostsForTopics: (params: any) => {
      dispatch(getAllPostsForTopics(params))
    },
    getPost: (postId: string) => {
      dispatch(getPost(postId))
    },
    getMe: () => {
      dispatch(getMe())
    },
    setClickedTopic: (topic: any) => {
      dispatch(setClickedTopic(topic))
    },
    setClickedPost: (post: any) => {
      dispatch(setClickedPost(post))
    },
    deletePost: (post: any, isVisible: any, reason: any) => {
      dispatch(deletePost(post, isVisible, reason))
    },
    voteTogglePost: (postId: any, topicId: any) => {
      dispatch(voteTogglePost(postId, topicId))
    },
    alarmTogglePost: (postId: any) => {
      dispatch(alarmTogglePost(postId))
    },
    addPostsToRedux: (postId: any) => {
      dispatch(addPostsToRedux(postId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopicScreen)
